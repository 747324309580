/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    $loading = null

    $toast = null

    constructor(axiosIns, jwtOverrideConfig) {
      this.axiosIns = axiosIns
      this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

      const that = this
      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          that.$loading.show({ loader: 'dots', color: '#059862' })
          const accessToken = this.getToken()
          let language = localStorage.getItem('language')
          if (!language) language = 'vi-VN'
          config.headers['Accept-Language'] = language
          // If token is present add it to request's Authorization Header
          if (accessToken) {
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }
          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => {
          const paras = document.getElementsByClassName('vld-overlay')
          while (paras[0]) {
            paras[0].parentNode.removeChild(paras[0])
          }
          return response
        },
        error => {
          const { response } = error
          const paras = document.getElementsByClassName('vld-overlay')
          while (paras[0]) {
            paras[0].parentNode.removeChild(paras[0])
          }
          switch (response.status) {
            case 400:
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Chưa đúng yêu cầu phía máy chủ',
                  icon: 'XIcon',
                  text: `${response.data.message}`,
                  variant: 'danger',
                },
              })
              break
            case 500:
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Lỗi máy chủ',
                  icon: 'XIcon',
                  text: `${response.data.message}`,
                  variant: 'danger',
                },
              })
              break
            case 401:
              if (response.data.error === 'unauthorized') {
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Lỗi tài khoản',
                    icon: 'XIcon',
                    text: 'Tài khoản hoặc mật khẩu chưa đúng',
                    variant: 'danger',
                  },
                })
              } else {
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Lỗi tài khoản',
                    icon: 'XIcon',
                    text: `${response.data.error}`,
                    variant: 'danger',
                  },
                })
              }
              break
            case 404:
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Không tìm thấy đường dẫn',
                  icon: 'XIcon',
                  text: `${response.data.message}`,
                  variant: 'danger',
                },
              })
              break
            case 410:
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Yêu cầu không thành công',
                  icon: 'XIcon',
                  text: `${response.data.message}`,
                  variant: 'danger',
                },
              })
              break
            case 409:
              that.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Yêu cầu thực hiện không hoàn thành',
                  icon: 'XIcon',
                  text: `${response.data.message}`,
                  variant: 'danger',
                },
              })
          }
          return Promise.reject(error)
        },
      )
    }

    onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
      this.subscribers.push(callback)
    }

    getToken() {
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }
    //
    // getRefreshToken() {
    //   return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    // }

    setToken(value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
      localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
      return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    logout(...args) {
      return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
    }

    register(...args) {
      return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
      return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
        refreshToken: this.getRefreshToken(),
      })
    }

    registrationForm() {
      return this.axiosIns.post(this.jwtConfig.registrationFormEndpoint)
    }

    registrationSuccess() {
      return this.axiosIns.post(this.jwtConfig.registrationSuccessEndpoint)
    }

    setLoading(loading) {
      this.$loading = loading
    }

    setToast(toast) {
      this.$toast = toast
    }
}
