import axios from 'axios'
import authConfig from '@/../domain.json'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

const authUser = axios.create({
  baseURL: authConfig.authUser,
})

authUser.interceptors.request.use(config => {
  Vue.prototype.$loading.show({ loader: 'dots', color: '#059862' })
  if (config.url === 'oauth/token') {
    config.headers = {
      Authorization: 'Basic Y2xpZW50SWQ6c2VjcmV0',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    return config
  }

  const accessToken = localStorage.getItem('access_token')
  let language = localStorage.getItem('language')
  if (!language) {
    language = 'vi-VN'
  }
  config.headers['Accept-Language'] = language

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}, error => Promise.reject(error))

authUser.interceptors.response.use(response => {
  hiddenLoading()
  return response
}, error => {
  const { response } = error
  hiddenLoading()
  if (response.config.url === '/oauth/token') {
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: 'Lỗi đăng nhập',
        icon: 'XIcon',
        text: 'Tài khoản hoặc mật khẩu không đúng',
        variant: 'danger',
      },
    })
    return Promise.reject(error)
  }

  if (response.status === 401) {
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        icon: 'XIcon',
        text: 'Hết hạn đăng nhập. Vui lòng đăng nhập lại!',
        variant: 'danger',
      },
    })

    return Promise.reject(error)
  }

  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      title: 'Lỗi máy chủ',
      icon: 'XIcon',
      text: `${response.data.message}`,
      variant: 'danger',
    },
  })

  return Promise.reject(error)
})

function hiddenLoading() {
  const paras = document.getElementsByClassName('vld-overlay')
  while (paras[0]) {
    paras[0].parentNode.removeChild(paras[0])
  }
}

export default authUser
