import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://crm.inviv.vn',
  // baseURL: 'http://103.171.92.154:8684/',
  // baseURL: 'http://192.168.4.77:8584',
  // headers: { 'Access-Control-Allow-Origin': true },
  // timeout: 1000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
