import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/manager-account',
      name: 'manager-account',
      component: () => import('@/views/account-management/AccountManagement.vue'),
    },
    {
      path: '/manager-employee',
      name: 'manager-employee',
      component: () => import('@/views/employee/ManagerEmployee.vue'),
    },
    {
      path: '/student-management/add-student',
      name: 'student-management/add-student',
      component: () => import('@/views/student/AddStudent.vue'),
      meta: {
        navActiveLink: 'student-management',
      },
    },
    {
      path: '/student-management/update-student',
      name: 'student-management/update-student',
      component: () => import('@/views/student/AddStudent.vue'),
      meta: {
        navActiveLink: 'student-management',
      },
    },
    {
      path: '/student-management',
      name: 'student-management',
      component: () => import('@/views/student/StudentManage.vue'),
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/user/ResetPassword.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/tuyen-sinh',
      name: 'admissions-form',
      component: () => import('@/views/admissions/Admissions.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/dang-ky',
      name: 'registration-form',
      component: () => import('@/views/registration-form/RegistrationForm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('@/views/registration-form/RegistrationSuccess.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/manager-account/add-manager-account',
      name: 'add-manager-account',
      component: () => import('@/views/account-management/AccountInformation.vue'),
      meta: {
        navActiveLink: 'manager-account',
      },
    },
    {
      path: '/manager-account/update-manager-account',
      name: 'update-manager-account',
      component: () => import('@/views/account-management/AccountInformation.vue'),
      meta: {
        navActiveLink: 'manager-account',
      },
    },
    {
      path: '/manager-employee/add-employee',
      name: 'manager-employee/add-employee',
      component: () => import('@/views/employee/EmployeeInformation.vue'),
      meta: {
        navActiveLink: 'manager-employee',
      },
    },
    {
      path: '/manager-employee/update-employee',
      name: 'manager-employee/update-employee',
      component: () => import('@/views/employee/EmployeeInformation.vue'),
      meta: {
        navActiveLink: 'manager-employee',
      },
    },
    {
      path: '/manage-work-shift-branch',
      name: 'manage-work-shift-branch',
      component: () => import('@/views/manage-work-shift-branch/ManageWorkShiftBranch.vue'),
      meta: {
        navActiveLink: 'manage-work-shift-branch',
      },
    },
    {
      path: '/manage-qr-code',
      name: 'manage-qr-code',
      component: () => import('@/views/qrCode/QrCodeManage.vue'),
      meta: {
        navActiveLink: 'manage-qr-code',
      },
    },
    {
      path: '/add-qr-code',
      name: 'add-qr-code',
      component: () => import('@/views/qrCode/QrCodeInformation.vue'),
      meta: {
        navActiveLink: 'manage-qr-code',
      },
    },
    {
      path: '/update-qr-code',
      name: 'update-qr-code',
      component: () => import('@/views/qrCode/QrCodeInformation.vue'),
      meta: {
        navActiveLink: 'manage-qr-code',
      },
    },
    {
      path: '/manage-tuition',
      name: 'manage-tuition',
      component: () => import('@/views/tuition-school/TuitionSchoolManage.vue'),
      meta: {
        navActiveLink: 'manage-tuition',
      },
    },
    {
      path: '/add-tuition',
      name: 'add-tuition',
      component: () => import('@/views/tuition-school/TuitionSchoolInformation.vue'),
      meta: {
        navActiveLink: 'manage-tuition',
      },
    },
    {
      path: '/update-tuition',
      name: 'update-tuition',
      component: () => import('@/views/tuition-school/TuitionSchoolInformation.vue'),
      meta: {
        navActiveLink: 'manage-tuition',
      },
    },
    {
      path: '/manage-document',
      name: 'manage-document',
      component: () => import('@/views/document/Document.vue'),
      meta: {
        navActiveLink: 'manage-document',
      },
    },
    {
      path: '/add-document',
      name: 'add-document',
      component: () => import('@/views/document/DocumentInformation.vue'),
      meta: {
        navActiveLink: 'manage-document',
      },
    },
    {
      path: '/update-document',
      name: 'update-document',
      component: () => import('@/views/document/DocumentInformation.vue'),
      meta: {
        navActiveLink: 'manage-document',
      },
    },
    {
      path: '/manage-wifi',
      name: 'manage-wifi',
      component: () => import('@/views/wifi/WifiManage.vue'),
      meta: {
        navActiveLink: 'manage-wifi',
      },
    },
    {
      path: '/add-wifi',
      name: 'add-wifi',
      component: () => import('@/views/wifi/WifiInformation.vue'),
      meta: {
        navActiveLink: 'manage-wifi',
      },
    },
    {
      path: '/update-wifi',
      name: 'update-wifi',
      component: () => import('@/views/wifi/WifiInformation.vue'),
      meta: {
        navActiveLink: 'manage-wifi',
      },
    },
    {
      path: '/add-work-shift',
      name: 'add-work-shift',
      component: () => import('@/views/manage-work-shift-branch/WorkShiftInformation.vue'),
      meta: {
        navActiveLink: 'manage-work-shift-branch',
      },
    },
    {
      path: '/update-work-shift',
      name: 'update-work-shift',
      component: () => import('@/views/manage-work-shift-branch/WorkShiftInformation.vue'),
      meta: {
        navActiveLink: 'manage-work-shift-branch',
      },
    },
    {
      path: '/time-keeping',
      name: 'time-keeping',
      component: () => import('@/views/time-keeping/Employee.vue'),
      meta: {
        navActiveLink: 'time-keeping',
      },
    },
    {
      path: '/time-keeping-detail',
      name: 'time-keeping-detail',
      component: () => import('@/views/time-keeping/TimeKeepingDetail.vue'),
      meta: {
        navActiveLink: 'time-keeping',
      },
    },
    {
      path: '/salary-employee',
      name: 'salary-employee',
      component: () => import('@/views/employee/ManagerSalary.vue'),
    },
    {
      path: '/department',
      name: 'manager-department',
      component: () => import('@/views/department/Department.vue'),
    },
    {
      path: '/manager-school-block',
      name: 'manager-school-block',
      component: () => import('@/views/department/manager-department/ManagerSchoolBlock.vue'),
    },
    {
      path: '/manager-school-group',
      name: 'manager-school-group',
      component: () => import('@/views/department/manager-department/ManagerSchoolGroup.vue'),
    },
    {
      path: '/department-school-block',
      name: 'department-school-block',
      component: () => import('@/views/department/schoolblock/SchoolBlock.vue'),
      meta: {
        navActiveLink: 'manager-department',
      },
    },
    {
      path: '/department-school-group',
      name: 'department-school-group',
      component: () => import('@/views/department/group/Group.vue'),
      meta: {
        navActiveLink: 'manager-department',
      },
    },
    {
      path: '/promotion',
      name: 'promotion',
      component: () => import('@/views/promotion/Promotion.vue'),
    },
    {
      path: '/manager-asset',
      name: 'manager-asset',
      component: () => import('@/views/asset/ManagerAsset.vue'),
    },
    {
      path: '/manager-asset/add-asset',
      name: 'add-asset',
      component: () => import('@/views/asset/AddOrEditAsset.vue'),
      meta: {
        navActiveLink: 'manager-asset',
      },
    },
    {
      path: '/manager-asset/update-asset',
      name: 'update-asset',
      component: () => import('@/views/asset/AddOrEditAsset.vue'),
      meta: {
        navActiveLink: 'manager-asset',
      },
    },
    {
      path: '/history-asset',
      name: 'history-asset',
      component: () => import('@/views/asset/HistoryAsset.vue'),
    },
    {
      path: '/manager-equipment',
      name: 'manager-item',
      component: () => import('@/views/item/ManagerItem.vue'),
    },
    {
      path: '/manager-equipment/add-equipment-device',
      name: 'add-item',
      component: () => import('@/views/item/AddOrEditItem.vue'),
      meta: {
        navActiveLink: 'manager-item',
      },
    },
    {
      path: '/manager-equipment/update-equipment-device',
      name: 'update-item',
      component: () => import('@/views/item/AddOrEditItem.vue'),
      meta: {
        navActiveLink: 'manager-item',
      },
    },
    {
      path: '/history-equipment',
      name: 'history-item',
      component: () => import('@/views/item/HistoryItem.vue'),
    },
    {
      path: '/manager-equipment/add-equipment-allocation',
      name: 'allocation-employee',
      component: () => import('@/views/item/Allocation.vue'),
      meta: {
        navActiveLink: 'manager-item',
      },
    },
    {
      path: '/allocation-recall/update-equipment-allocation',
      name: 'update-allocation-employee',
      component: () => import('@/views/item/Allocation.vue'),
      meta: {
        navActiveLink: 'allocation-recall',
      },
    },
    {
      path: '/allocation-recall-equipment',
      name: 'allocation-recall-device',
      component: () => import('@/views/item/AllocationRecall.vue'),
    },
    {
      path: '/manager-item/allocation-asset-employee',
      name: 'allocation-asset',
      component: () => import('@/views/asset/Allocation.vue'),
    },
    {
      path: '/allocation-recall-asset',
      name: 'allocation-recall-asset',
      component: () => import('@/views/asset/AllocationRecall.vue'),
    },
    {
      path: '/allocation-recall-asset/update-allocation-asset',
      name: 'update-allocation-asset',
      component: () => import('@/views/asset/Allocation.vue'),
      meta: {
        navActiveLink: 'allocation-recall-asset',
      },
    },
    {
      path: '*',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
